<template>
    <b-modal id="cancel-vaccination" no-close-on-esc no-close-on-backdrop hide-header hide-footer centered
        v-model="showModal">
        <div class="d-flex flex-column my-2">
            <h2>Deseja cancelar a vacinação?</h2>
            <span v-if="pessoaRemover > 0" class="pb-1 text-danger">{{ pessoaRemover }} pessoas selecionadas.</span>
            <validation-observer ref="itemRules">
                <b-form>
                    <b-row>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group class="mt-2" label="Justificativa*" label-for="justificativa-input">
                                <validation-provider #default="{ errors }" name="justificativa" rules="required|min:10">
                                    <b-form-input id="justificativa-input" v-model="item.justificativa"
                                        placeholder="Insira uma justificativa" />
                                    <small v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </div>
        <section class="d-flex justify-content-center mb-1">
            <b-button id="save-cancellation" class="mr-2 mb-2-sm" variant="custom-blue" @click.prevent="confirmAction"
                :disabled="saving">
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Sim, cancelar</span>
                <output v-if="saving" class="spinner-border spinner-border-sm ml-1" aria-hidden="true"></output>
            </b-button>
            <b-button id="cancel-cancellation" :disabled="saving" variant="outline-danger"
                @click.prevent="cancelAction">
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Não</span>
            </b-button>
        </section>
    </b-modal>
</template>

<script>
import {
    BModal, BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, VBTooltip, BSpinner, BCard,
    BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueCleave from 'vue-cleave-component'
import handleErrorsService from '@/views/custom-pages/colaborador/Services/HandleErrors';
import { required } from '@validations'
import vSelect from 'vue-select';

export default {
    components: {
        BModal, BForm, BCol, BRow, BFormGroup, BFormInput,
        BButton, BTable, VBTooltip, BSpinner, BCard, vSelect,
        BFormDatepicker, BInputGroup, BInputGroupAppend,
        ValidationProvider, ValidationObserver, VueCleave, BAlert, handleErrorsService
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        colaborador: {
            type: Object,
            default: null
        },
        pessoaRemover: {
            type: Number,
            required: false,
        }
    },
    data() {
        return {
            required,
            item: {
                justificativa: '',
            },
            saving: false
        }
    },
    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();
            this.$refs.itemRules.validate().then(success => {
                if (success) {
                    this.saving = true;
                    this.$emit('confirmed', this.item);
                    this.$refs.itemRules.reset();
                    this.emptyForm();
                }
            });
        },

        cancelAction(modalEvent) {
            modalEvent.preventDefault();
            this.$emit('canceled', modalEvent);
            this.$refs.itemRules.reset();
            this.emptyForm();
        },
        emptyForm() {
            this.item.justificativa = '';
            this.saving = false;
        },
    },
}
</script>

<style scoped>
.bloco {
    height: 200px;
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.panel-enter-active,
.panel-leave-active {
    transition: all 0.5s ease;
}

.panel-enter-from,
.panel-leave-to {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
}
</style>