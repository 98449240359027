<template>
    <b-modal span v-if="colaborador" id="cancellation-info" no-close-on-esc no-close-on-backdrop hide-header hide-footer
        centered v-model="showModal">
        <div class="d-flex flex-column my-2">
            <h2>Informações do cancelamento</h2>
            <b-form
                v-for="(cancelamento, index) in colaborador.slice().sort((a, b) => new Date(b.aplicacao.data_cancelamento) - new Date(a.aplicacao.data_cancelamento))"
                :key="index">
                <b-row>
                    <b-col lg="12" md="12" sm="12">
                        <b-form-group class="mt-2" label="Usuário responsável pelo cancelamento"
                            :label-for="'usuario-input-' + index">
                            <b-form-input :id="'usuario-input-' + index" disabled
                                :value="cancelamento.aplicacao.nome_usuario_cancelamento" />
                        </b-form-group>

                        <b-form-group class="mt-2" label="Data e hora do cancelamento"
                            :label-for="'data-input-' + index">
                            <b-form-input :id="'data-input-' + index"
                                :value="formatDateTime(cancelamento.aplicacao.data_cancelamento)" disabled />
                        </b-form-group>

                        <b-form-group class="mt-2" label="Motivo do cancelamento"
                            :label-for="'motivo-cancelamento-input-' + index">
                            <b-form-input :id="'motivo-cancelamento-input-' + index" disabled
                                :value="cancelamento.aplicacao.motivo_cancelamento" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr v-if="index < colaborador.length - 1" />
            </b-form>
        </div>
        <section class="d-flex justify-content-center mb-1">
            <b-button id="cancel-info" variant="outline-danger" @click.prevent="cancelAction">
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Voltar</span>
            </b-button>
        </section>
    </b-modal>
</template>

<script>
import {
    BModal, BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, VBTooltip, BSpinner, BCard,
    BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueCleave from 'vue-cleave-component'
import handleErrorsService from '@/views/custom-pages/colaborador/Services/HandleErrors';
import vSelect from 'vue-select';

export default {
    components: {
        BModal, BForm, BCol, BRow, BFormGroup, BFormInput,
        BButton, BTable, VBTooltip, BSpinner, BCard, vSelect,
        BFormDatepicker, BInputGroup, BInputGroupAppend,
        ValidationProvider, ValidationObserver, VueCleave, BAlert, handleErrorsService
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        colaborador: {
            type: Array,
            default: null
        }
    },
    methods: {
        cancelAction(modalEvent) {
            modalEvent.preventDefault();
            this.$emit('canceled', modalEvent);
        },

        formatDateTime(dateTime) {
            if (!dateTime) return 'Sem data';
            const date = new Date(dateTime);
            return date.toLocaleString('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short'
            });
        }
    },
}
</script>

<style scoped>
.bloco {
    height: 200px;
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.panel-enter-active,
.panel-leave-active {
    transition: all 0.5s ease;
}

.panel-enter-from,
.panel-leave-to {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
}
</style>